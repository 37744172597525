import Docpublisher from "./docpublisher.vue";
import ProjectList from "./projectlist.vue";

import BootstrapVue from "bootstrap-vue";
import Vue from "vue";
Vue.use(BootstrapVue);

import { checkAccessToService } from "../../../middleware/secure-route";

// Define an array of route objects
export default [
  {
    // Define a route for the Docpublisher component with dynamic parameters
    path: "/doceditor/docpublisher/:repouser/:reponame/:repobranch",
    component: Docpublisher,
    name: "Docpublisher",
    props: (route) => ({
      // Decode and pass dynamic parameters from the URL as props to the component
      repouser: decodeURIComponent(route.params.repouser),
      reponame: decodeURIComponent(route.params.reponame),
      repobranch: decodeURIComponent(route.params.repobranch),
    }),
    beforeEnter: (to, from, next) => {
      checkAccessToService(to, from, next, "publisher");
    },
  },
  {
    // Define a route for the ProjectList component
    path: "/doceditor",
    component: ProjectList,
    name: "DocEditor",
    beforeEnter: (to, from, next) => {
      checkAccessToService(to, from, next, "editor");
    },
  },
];
