<template>
  <div>
    <!-- Display a page header with title and breadcrumb items -->
    <PageHeader :title="title" :items="items" />

    <div class="row align-items-center">
      <div class="col-lg-6 col-sm-12 mb-3">
        <div class="card">
          <div class="card-body">
            <Chart />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12 mb-3">
        <InfoCard />
      </div>
      <div class="col-md-6 col-sm-12 mb-3">
        <RecentPublication class="m-0 p-0" />
      </div>
      <div class="col-md-6 col-sm-12">
        <!-- RecentNotification Component for displaying recent notifications -->
        <RecentNotification />
      </div>
    </div>
  </div>
</template>

<script>
import RecentNotification from "./components/recentnotification.vue";
import RecentPublication from "./components/recentpublication.vue";
import Chart from "./components/chart.vue";
import InfoCard from "./components/InfoCard.vue";

import { eventBus } from "../../../main";

export default {
  name: "Dashboard",

  components: {
    RecentNotification,
    RecentPublication,
    Chart,
    InfoCard,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  mounted() {
    // Emit an event to update the sidebar with the current menu item
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
  },
};
</script>

<style scoped>
/* Remove the unnecessary styling for .column */
@media (max-width: 991px) {
  .recPubl {
    order: 3;
  }
}
</style>
